<template>
  <bottom-up-modal
    :title="$t('edit_users_2')"
    :isVisible="isVisible"
    @closeModal="$emit('close')"
  >
    <!-- create user form --->
    <div class="flex flex-row flex-wrap justify-between">
      <input-text
        class="w-full sm:w-50-5  lg:w-64 mb-5"
        :id="'user_firstname'"
        :label="$t('first_name')"
        :name="'firstname'"
        :labelClass="'bg-white'"
        v-model="GET_CUSTOMER_USER.firstname"
      ></input-text>
      <input-text
        class="w-full sm:w-50-5  lg:w-64 mb-5"
        :id="'user_last_name'"
        :labelClass="'bg-white'"
        :label="$t('last_name')"
        :name="'lastname'"
        v-model="GET_CUSTOMER_USER.lastname"
      ></input-text>
      <input-text
        class="w-full sm:w-50-5  lg:w-64 mb-5"
        :id="'user_email'"
        :label="$t('email')"
        :name="'email'"
        :labelClass="'bg-white'"
        v-model="GET_CUSTOMER_USER.email"
      ></input-text>
    </div>

    <!-- permissions check box --->
    <div class="flex flex-col gap-5 mt-5">
      <div v-for="permission in GET_PERMISSIONS" :key="permission.id">
        <input-checkbox
          :id="'user_role'"
          :name="'permission_' + permission.name"
          :defaultColor="'grey-dark'"
          :activeColor="'primary'"
          :label="$t(permission.name)"
          @change="togglePermission(permission)"
          :modelValue="checkHasPermission(permission)"
        />
      </div>
    </div>

    <!--- action buttons -->
    <div
      class="flex flex-row justify-end mb-6"
      v-if="GET_CUSTOMER_USER != null && GET_CUSTOMER_USER.id != null"
    >
      <custom-button
        class="w-44 h-11 mr-4"
        :bgColor="'bg-grey-fontgrey'"
        :textColor="'text-white'"
        :text="$t('cancel')"
        :id="'abort-user-button'"
        @click="set_back()"
      />
      <custom-button
        class="w-44 h-11"
        :text="$t('save')"
        :bgColor="'bg-primary'"
        :textColor="'text-white'"
        :id="'add-user-button'"
        @click="UPDATE_CUSTOMER_USER()"
      />
    </div>
    <div class="flex flex-row justify-end mb-6" v-else>
      <custom-button
        class="w-44 h-11"
        :text="$t('send_invitation')"
        :bgColor="'bg-primary'"
        :textColor="'text-white'"
        :id="'add_customer_user'"
        @click="CREATE_CUSTOMER_USER()"
      />
    </div>

    <div class="w-full border rounded-lg border-grey-light  mb-6"></div>

    <!--- users container --->
    <h2 class="font-semibold text-grey-dark mb-3">
      {{ $t("user") }}
    </h2>

    <!--- search input --->
    <div class="flex items-center">
      <searchbar
        :id="'search'"
        v-model="state.search"
        :label="$t('search')"
        :labelClass="'bg-white'"
        :activeColor="'primary'"
        class="mt-3 w-1/2 bg-white"
        :placeHolder="$t('name')"
        @update="
          (value) => {
            state.search = value;
          }
        "
      />
    </div>

    <!--- user-list -->
    <div class="settings-card-box scrollbar overflow-auto pr-1 ">
      <list-transition>
        <regular-card
          class="w-full mb-3 bg-white-dark bg-opacity-40 hover:bg-opacity-100 list-transition-item"
          v-for="(user, index) in filteredUsers"
          :key="index"
          :isActive="GET_CUSTOMER_USER.id == user.id"
          :description="
            `${user.email}
                        `
          "
          :header="user.fullname"
        >
          <template #avatar
            ><avatar-bubble :name="user.fullname" class="mr-3"
          /></template>
          <template #icons
            ><div class="flex flex-row items-center justify-end">
              <icon-container
                class="hover:bg-grey hover:bg-opacity-20 mr-3"
                icon="EditIcon"
                iconFill="fill-primary"
                :title="$t('edit_user')"
                @click="changeMode('edit', user)"
              ></icon-container>

              <icon-container
                class="hover:bg-grey hover:bg-opacity-20  mr-1"
                icon="TrashIcon"
                iconFill="fill-primary"
                :title="$t('delete_user')"
                @click="deleteUser(user)"
              ></icon-container></div
          ></template>
        </regular-card>

        <!--- empty feedback-->
        <empty-list-feedback
          v-if="
            filteredCustomerUsers == null || filteredCustomerUsers.length == 0
          "
          :color="'primary'"
          class="mt-4"
          :text="$t('toast.no_users')"
          :key="'empty-feedback'"
        ></empty-list-feedback>
      </list-transition>

      <!--- user list -->
      <user-list
        v-for="user of filteredCustomerUsers"
        :key="user.id"
        :user="user"
        @editUser="edit_user(user)"
        @deleteUser="delete_user(user)"
      />
    </div>

    <!-- delete user modal --->
    <delete-modal
      :isVisible="state.openDeleteModal"
      @closeDeleteModal="state.openDeleteModal = false"
      @delete="DELETE_USER()"
    />
  </bottom-up-modal>
</template>

<script>
import { computed, defineComponent, reactive, watch } from "vue";
import {
  AUTH_TYPES,
  CUSTOMER_USERS_TYPES,
  HELPERS_TYPES,
  STORE_TYPES,
} from "../../../store/types";
import { useStore } from "vuex";

import BottomUpModal from "../../../components/layout/BottomUpModal.vue";
import InputText from "../../../components/input/InputText.vue";
import InputCheckbox from "../../../components/input/InputCheckbox.vue";
import CustomButton from "../../../components/input/CustomButton.vue";
import ListTransition from "../../../components/transitions/ListTransition.vue";
import RegularCard from "../../../components/global/RegularCard.vue";
import IconContainer from "../../../components/global/IconContainer.vue";
import EmptyListFeedback from "../../../components/global/EmptyListFeedback.vue";
import UserList from "../components/UserList.vue";
import DeleteModal from "./DeleteModal.vue";
import Searchbar from "../../../components/layout/Searchbar.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    BottomUpModal,
    InputText,

    InputCheckbox,
    CustomButton,
    ListTransition,
    RegularCard,
    IconContainer,
    EmptyListFeedback,
    UserList,
    DeleteModal,
    Searchbar,
  },
  name: "EditUsersModal",
  emits: ["close"],
  props: {
    isVisible: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    /**
     *  State
     */
    const state = reactive({
      isOpen: false,
      openDeleteModal: false,
      search: "",
    });
    /*
     * Computeds
     */

    const GET_CUSTOMER_USERS = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_USERS}/${CUSTOMER_USERS_TYPES.GET_CUSTOMER_USERS}`
      ];
    });
    const GET_PERMISSIONS = computed(() => {
      return store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_PERMISSIONS}`];
    });

    const GET_CUSTOMER_USER = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_USERS}/${CUSTOMER_USERS_TYPES.GET_CUSTOMER_USER}`
      ];
    });

    const GET_USER = computed(
      () => store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`]
    );

    const filteredCustomerUsers = computed(() => {
      if (GET_CUSTOMER_USERS.value == null) {
        return;
      }

      // FILTERED USERS WITHOUT AUTH_USER
      var filteredUsers = GET_CUSTOMER_USERS.value.filter(function(user) {
        return user.id != GET_USER.value.id;
      });

      return filteredUsers.filter((search) => {
        return search.fullname.toLowerCase().match(state.search.toLowerCase());
      });
    });

    /** Functions */

    const checkHasPermission = (permission) => {
      if (
        GET_CUSTOMER_USER.value == null &&
        GET_CUSTOMER_USER.value.permissions == null
      ) {
        return false;
      } else if (GET_CUSTOMER_USER.value.permissions != null) {
        return (
          GET_CUSTOMER_USER.value.permissions.find((onePermission) => {
            return onePermission.id == permission.id;
          }) != null
        );
      }
    };

    const togglePermission = (permission) => {
      if (GET_CUSTOMER_USER.value.permissions == null) {
        GET_CUSTOMER_USER.value.permissions = [];
      }
      if (checkHasPermission(permission)) {
        GET_CUSTOMER_USER.value.permissions.splice(
          GET_CUSTOMER_USER.value.permissions.findIndex((onePermission) => {
            return onePermission.id == permission.id;
          }),
          1
        );
      } else if (GET_CUSTOMER_USER.value.permissions != null) {
        GET_CUSTOMER_USER.value.permissions.push(permission);
      }
    };

    const delete_user = (user) => {
      state.openDeleteModal = true;
      SET_CUSTOMER_USER(user);
    };

    function FETCH_CUSTOMER_USERS() {
      store.dispatch(
        `${STORE_TYPES.CUSTOMER_USERS}/${CUSTOMER_USERS_TYPES.FETCH_CUSTOMER_USERS}`,
        GET_USER.value.customer_id
      );
    }
    function FETCH_PERMISSIONS() {
      store.dispatch(`${STORE_TYPES.AUTH}/${AUTH_TYPES.FETCH_PERMISSIONS}`);
    }

    // CREATE CustomerUser
    const CREATE_CUSTOMER_USER = async () => {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "add_customer_user"
      );
      await store.dispatch(
        `${STORE_TYPES.CUSTOMER_USERS}/${CUSTOMER_USERS_TYPES.CREATE_CUSTOMER_USER}`,
        GET_USER.value.customer_id
      );
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "add_customer_user"
      );
      set_back();
    };
    // DELETE  CustomerUser
    const DELETE_USER = async () => {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "delete_user"
      );
      await store.dispatch(
        `${STORE_TYPES.CUSTOMER_USERS}/${CUSTOMER_USERS_TYPES.DELETE_CUSTOMER_USER}`,
        GET_USER.value.customer_id,
        GET_CUSTOMER_USER.value.id
      );
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "delete_user"
      );
      set_back();
    };

    // update CustomerUser
    const UPDATE_CUSTOMER_USER = async (input_id) => {
      if (
        GET_CUSTOMER_USER.value == null ||
        GET_CUSTOMER_USER.value.id == null
      ) {
        return;
      }
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        input_id
      );

      await store.dispatch(
        `${STORE_TYPES.CUSTOMER_USERS}/${CUSTOMER_USERS_TYPES.UPDATE_CUSTOMER_USER}`,
        GET_CUSTOMER_USER.value
      );

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        input_id
      );
      set_back();
    };

    const SET_CUSTOMER_USER = (user) => {
      return store.commit(
        `${STORE_TYPES.CUSTOMER_USERS}/${CUSTOMER_USERS_TYPES.SET_CUSTOMER_USER}`,
        user
      );
    };

    const edit_user = (user) => {
      SET_CUSTOMER_USER(user);
      state.isOpen = true;
    };

    const set_back = () => {
      SET_CUSTOMER_USER({});
      state.isOpen = false;
    };

    watch(
      () => props.isVisible,
      (newValue) => {
        if (newValue == true) {
          if (GET_USER.value != null && GET_USER.value.id != null) {
            FETCH_CUSTOMER_USERS(newValue.customer_id);
            FETCH_PERMISSIONS();
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    return {
      state,
      store,

      delete_user,
      edit_user,
      set_back,
      togglePermission,
      checkHasPermission,

      router,
      GET_USER,
      GET_PERMISSIONS,
      GET_CUSTOMER_USERS,
      GET_CUSTOMER_USER,
      filteredCustomerUsers,
      CREATE_CUSTOMER_USER,
      UPDATE_CUSTOMER_USER,
      DELETE_USER,
    };
  },
});
</script>

<style lang="scss">
// .settings-card-box {
//     max-height: calc(100vh - 520px);
//     @screen sm {
//         max-height: calc(100vh - 480px);
//     }
//     min-height: 200px;
// }
//
</style>
