<template>
  <bottom-up-modal
    :title="$t('product_carrier_designation_2')"
    :isVisible="isVisible"
    @closeModal="$emit('close')"
  >
    <div v-if="GET_SETTINGS != null">
      <input-checkbox
        :id="'active_desig'"
        :defaultColor="'grey-dark'"
        :activeColor="'primary'"
        :label="$t('active_consecutive_number_start_at_1000000')"
        :modelValue="GET_SETTINGS.is_auto_name"
        @change="toggleSettings(state.setting)"
      />
    </div>

    <div class="flex flex-col w-full">
      <div class="mt-5 flex gap-4">
        <custom-button
          class="h-11 w-full sm:w-1/4 ml-auto"
          :bgColor="'bg-primary'"
          :textColor="'text-white'"
          :text="$t('save')"
          :id="'update_settings'"
          @click="UPDATE_SETTINGS()"
        />
      </div>
    </div>
  </bottom-up-modal>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import CustomButton from "../../../components/input/CustomButton.vue";
import BottomUpModal from "../../../components/layout/BottomUpModal.vue";
import { computed, defineComponent, reactive, watch } from "vue";
import InputCheckbox from "../../../components/input/InputCheckbox.vue";
import {
  AUTH_TYPES,
  CUSTOMERS_TYPES,
  HELPERS_TYPES,
  STORE_TYPES,
} from "../../../store/types";

export default defineComponent({
  components: {
    CustomButton,
    BottomUpModal,
    InputCheckbox,
  },
  name: "DesignationModal",
  emits: ["close"],
  props: {
    isVisible: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, ctx) {
    const store = useStore();
    const router = useRouter();

    /**
     *  State
     */
    const state = reactive({
      isOpen: false,
      setting: {
        is_auto_name: false,
      },

      openDeleteModal: false,
    });

    /*
     * Computeds
     */
    const GET_USER = computed(
      () => store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`]
    );

    const GET_SETTINGS = computed(
      () =>
        store.getters[
          `${STORE_TYPES.CUSTOMERS}/${CUSTOMERS_TYPES.GET_CUSTOMER_SETTINGS}`
        ]
    );

    /*
     * Functions
     */

    const toggleSettings = (setting) => {
      setting = GET_SETTINGS.value;
      if (setting.is_auto_name == false) {
        setting.is_auto_name = true;
        SET_SETTINGS(setting);
      } else if (setting.is_auto_name == true) {
        setting.is_auto_name = false;
        SET_SETTINGS(setting);
      } else {
        return;
      }
    };

    const SET_SETTINGS = (autoName) => {
      return store.commit(
        `${STORE_TYPES.CUSTOMERS}/${CUSTOMERS_TYPES.SET_CUSTOMER_SETTINGS}`,
        autoName
      );
    };

    const UPDATE_SETTINGS = async () => {
      if (GET_USER.value == null || GET_USER.value.id == null) {
        return;
      }
      const settingsData = {
        id: GET_USER.value.customer_id,
        customer_settings: GET_SETTINGS.value,
      };
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "update_settings"
      );
      await store.dispatch(
        `${STORE_TYPES.CUSTOMERS}/${CUSTOMERS_TYPES.UPDATE_CUSTOMER_SETTINGS}`,
        settingsData
      );

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "update_settings"
      );

      ctx.emit("close");
    };

    const FETCH_SETTINGS = async () => {
      await store.dispatch(
        `${STORE_TYPES.CUSTOMERS}/${CUSTOMERS_TYPES.FETCH_SETTINGS}`,
        GET_USER.value.customer_id
      );
    };

    watch(
      () => props.isVisible,
      (newValue) => {
        if (newValue == true) {
          if (GET_USER.value != null && GET_USER.value.id != null) {
            FETCH_SETTINGS(GET_USER.value.customer_id);
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    return {
      state,
      store,
      router,

      GET_SETTINGS,
      UPDATE_SETTINGS,
      toggleSettings,
    };
  },
});
</script>
