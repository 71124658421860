<template>
  <bottom-up-modal
    :title="$t('edit_fields_2')"
    :isVisible="isVisible"
    @closeModal="$emit('close')"
  >
    <div class=" mt-7 mb-7  flex flex-row items-center w-full gap-3">
      <input-text
        :id="'name'"
        class="w-1/3"
        :label="$t('name')"
        :name="'key'"
        :labelClass="'bg-white'"
        v-model="GET_DATA_FIELD.key"
      />

      <input-select
        :id="'type'"
        :activeColor="'grey-fontgrey'"
        :name="'type'"
        :label="$t('type')"
        :labelClass="'bg-white'"
        :background="'bg-white'"
        ref="dropdown"
        :nameColumn="'name'"
        :options="state.fieldTypes"
        :activeItems="SELECTED_TYPE"
        class="w-1/3"
        @changeItem="
          (item) => {
            select_fieldType(item);
            $refs.dropdown.close();
          }
        "
      />
      <input-checkbox
        class="w-1-3 ml-6"
        :name="'required'"
        :id="'required_field'"
        v-model="GET_DATA_FIELD.required"
        :label="$t('mandatory_field')"
        :defaultColor="'grey-fontgrey'"
        :activeColor="'primary'"
        @change="GET_DATA_FIELD.required = !GET_DATA_FIELD.required"
      />
    </div>

    <div
      class="flex flex-col w-full"
      v-if="GET_DATA_FIELD != null && GET_DATA_FIELD.id != null"
    >
      <!-- @click="$emit('close')" -->

      <div class="mt-5 flex gap-4">
        <custom-button
          class="h-11 w-full sm:w-1/4 ml-auto"
          :bgColor="'bg-grey-fontgrey'"
          :textColor="'text-white'"
          :text="$t('abort')"
          :id="'abort'"
          @click="set_back()"
        />
        <custom-button
          class="h-11 w-full sm:w-1/4"
          :bgColor="'bg-primary'"
          :textColor="'text-white'"
          :text="$t('edit_2')"
          :id="'create_dataField'"
          @click="UPDATE_DATA_FIELD()"
        />
      </div>
    </div>
    <div class="flex mt-5 " v-else>
      <custom-button
        :id="'add_data_field'"
        class="h-11 sm:w-1/5 w-1/2 ml-auto"
        :bgColor="'bg-primary'"
        :textColor="'text-white'"
        :text="$t('add')"
        @click="CREATE_DATA_FIELD()"
      />
    </div>
    <!-- --------------------------  FIELD LIST  -------------------------- -->
    <div class="w-full h-px bg-primary-light mt-7"></div>
    <div class="mt-7 font-semibold text-lg">
      {{ $t("individual_fields") }}
    </div>

    <div class="flex items-center mb-3">
      <searchbar
        :id="'search'"
        v-model="state.search"
        :label="$t('search')"
        :labelClass="'bg-white'"
        :activeColor="'primary'"
        class="mt-3 w-1/2 bg-white"
        :placeHolder="$t('name')"
        @update="
          (value) => {
            state.search = value;
          }
        "
      />
    </div>

    <div
      class="bg-white-dark mb-3 rounded-md w-full hover:border-grey-fontgrey"
      :class="
        GET_DATA_FIELD.id == dataField.id
          ? 'border-t-4 border-primary'
          : 'border border-transparent'
      "
      v-for="dataField of filteredDataFields"
      :key="dataField.id"
      :dataField="dataField"
    >
      <div class="p-2 flex items-center">
        <div class="flex flex-col">
          <div
            class="text-md font-semibold  box-content inline-block md:text-md"
          >
            {{ dataField.key }}
          </div>
          <div
            class="text-md md:text-sm flex flex-row flex-wrap justify-start gap-1"
          >
            <div>{{ $t("groups") }}:</div>
            <div
              v-for="group of dataField.dataModels"
              :key="group.id"
              :group="group"
            >
              {{ group ? group.name : "-" }},
            </div>
          </div>
        </div>
        <div class="ml-auto md:mr-7">
          <div class="flex items-center space-x-6 ">
            <div @click="edit_field(dataField)">
              <edit-icon class="cursor-pointer mr-1" />
            </div>
            <trash-icon
              class="cursor-pointer"
              @click="delete_field(dataField)"
            />
          </div>
        </div>
      </div>
    </div>
    <delete-modal
      :isVisible="state.openDeleteModal"
      @closeDeleteModal="state.openDeleteModal = false"
      @delete="DELETE_DATA_FIELD()"
    />
  </bottom-up-modal>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import EditIcon from "../../../components/icons/EditIcon.vue";
import CustomButton from "../../../components/input/CustomButton.vue";
import InputCheckbox from "../../../components/input/InputCheckbox.vue";
import InputSelect from "../../../components/input/InputSelect.vue";
import InputText from "../../../components/input/InputText.vue";
import BottomUpModal from "../../../components/layout/BottomUpModal.vue";
import Searchbar from "../../../components/layout/Searchbar.vue";
import {
  AUTH_TYPES,
  DATA_FIELDS_TYPES,
  HELPERS_TYPES,
  STORE_TYPES,
} from "../../../store/types";

import DeleteModal from "./DeleteModal.vue";

export default defineComponent({
  components: {
    CustomButton,
    InputText,

    BottomUpModal,
    EditIcon,
    DeleteModal,
    Searchbar,
    InputCheckbox,
    InputSelect,
  },
  name: "EditFieldModal",
  emits: ["close"],
  props: {
    isVisible: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const store = useStore();
    const router = useRouter();

    /**
     *  State
     */
    const state = reactive({
      required: false,
      isOpen: false,
      openDeleteModal: false,
      search: "",
      fieldTypes: [
        { name: i18n.t("boolean"), id: "boolean" },
        { name: i18n.t("numeric"), id: "numeric" },
        { name: i18n.t("string"), id: "string" },
      ],
    });
    /*
     * Computeds
     */

    const GET_DATA_FIELDS = computed(() => {
      return store.getters[
        `${STORE_TYPES.DATA_FIELDS}/${DATA_FIELDS_TYPES.GET_DATA_FIELDS}`
      ];
    });

    const filteredDataFields = computed(() => {
      return store.getters[
        `${STORE_TYPES.DATA_FIELDS}/${DATA_FIELDS_TYPES.GET_DATA_FIELDS}`
      ].filter((search) => {
        return search.key.toLowerCase().match(state.search.toLowerCase());
      });
    });

    const SELECTED_TYPE = computed(() => {
      if (GET_DATA_FIELD.value != null && GET_DATA_FIELD.value.type != null) {
        return [
          {
            id: GET_DATA_FIELD.value.type,
            name: i18n.t(GET_DATA_FIELD.value.type),
          },
        ];
      }
      return [];
    });

    const GET_DATA_FIELD = computed(() => {
      return store.getters[
        `${STORE_TYPES.DATA_FIELDS}/${DATA_FIELDS_TYPES.GET_DATA_FIELD}`
      ];
    });
    const GET_DELETE_DATA_FIELD = computed(() => {
      return store.getters[
        `${STORE_TYPES.DATA_FIELDS}/${DATA_FIELDS_TYPES.GET_DELETE_DATA_FIELD}`
      ];
    });

    const GET_USER = computed(
      () => store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`]
    );

    /** Functions */

    // FETCH
    function FETCH_DATA_FIELDS() {
      store.dispatch(
        `${STORE_TYPES.DATA_FIELDS}/${DATA_FIELDS_TYPES.FETCH_DATA_FIELDS}`,
        GET_USER.value.customer_id
      );
    }

    // CREATE DataField
    const CREATE_DATA_FIELD = async () => {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "add_data_field"
      );
      const response = await store.dispatch(
        `${STORE_TYPES.DATA_FIELDS}/${DATA_FIELDS_TYPES.CREATE_DATA_FIELD}`,
        GET_USER.value.customer_id
      );
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "add_data_field"
      );
      if (response.status === 200) {
        SET_DATA_FIELD({});
      }
    };

    // update DataField
    const UPDATE_DATA_FIELD = async (input_id) => {
      let dataFieldData = {
        customer_id: GET_USER.value.customer_id,
        data_field: GET_DATA_FIELD.value,
      };
      if (GET_DATA_FIELD.value == null || GET_DATA_FIELD.value.id == null) {
        return;
      }
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        input_id
      );

      await store.dispatch(
        `${STORE_TYPES.DATA_FIELDS}/${DATA_FIELDS_TYPES.UPDATE_DATA_FIELD}`,
        dataFieldData
      );

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        input_id
      );
      SET_DATA_FIELD({});
    };

    // DELETE DATA FIELD
    const DELETE_DATA_FIELD = async () => {
      let dataFieldData = {
        customer_id: GET_USER.value.customer_id,
        data_field: GET_DELETE_DATA_FIELD.value,
      };

      if (
        GET_DELETE_DATA_FIELD.value == null ||
        GET_DELETE_DATA_FIELD.value.id == null
      ) {
        return;
      }

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "deleteDataField"
      );

      await store.dispatch(
        `${STORE_TYPES.DATA_FIELDS}/${DATA_FIELDS_TYPES.DELETE_DATA_FIELD}`,
        dataFieldData
      );

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "deleteDataField"
      );
      state.openDeleteModal = false;
    };

    const delete_field = (dataField) => {
      state.openDeleteModal = true;
      SET_DELETE_DATA_FIELD(dataField);
    };

    const SET_DATA_FIELD = (dataField) => {
      return store.commit(
        `${STORE_TYPES.DATA_FIELDS}/${DATA_FIELDS_TYPES.SET_DATA_FIELD}`,
        dataField
      );
    };
    const SET_DELETE_DATA_FIELD = (dataField) => {
      return store.commit(
        `${STORE_TYPES.DATA_FIELDS}/${DATA_FIELDS_TYPES.SET_DELETE_DATA_FIELD}`,
        dataField
      );
    };

    const edit_field = (dataField) => {
      SET_DATA_FIELD(dataField);
      state.isOpen = true;
    };

    const set_back = () => {
      SET_DATA_FIELD({});
      state.isOpen = false;
    };

    const select_fieldType = (item) => {
      GET_DATA_FIELD.value.type = item.id;
      // if (GET_DATA_FIELD.value != null) {
      //   UPDATE_DATA_FIELD(GET_DATA_FIELD.value.type);
      // }
    };

    /** Mounted  */
    onMounted(() => {});
    watch(
      () => props.isVisible,
      (newValue) => {
        if (newValue == true) {
          if (GET_USER.value != null && GET_USER.value.id != null) {
            FETCH_DATA_FIELDS(GET_USER.value.customer_id);
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    return {
      state,
      store,
      router,

      GET_USER,
      GET_DATA_FIELDS,
      GET_DATA_FIELD,
      filteredDataFields,
      SELECTED_TYPE,

      CREATE_DATA_FIELD,
      UPDATE_DATA_FIELD,
      DELETE_DATA_FIELD,

      select_fieldType,
      delete_field,
      edit_field,
      set_back,
    };
  },
});
</script>
