<template>
  <div
    class=" bg-white-dark rounded-lg p-3 mt-3 hover:border-grey-fontgrey"
    :class="
      GET_CUSTOMER_USER.id == user.id
        ? 'border-t-4 border-primary'
        : 'border border-transparent'
    "
  >
    <div class="flex items-center">
      <div class="mr-2">
        <avatar-bubble
          class="mr-4"
          ref="avatarBubble"
          :name="user.fullname"
          :src="user.avatar == null ? null : user.avatar.picture_path"
        />
      </div>

      <div class=" flex flex-col">
        <div class=" font-semibold">
          {{ user.firstname }}, {{ user.lastname }}
        </div>
        <div class="text-sm text-grey-fontgrey">
          {{ $t("role") }}:
          {{
            user.permissions != null && user.permissions.length != 0
              ? "Admin"
              : "User"
          }}
        </div>
      </div>
      <div class="flex items-center ml-auto gap-6 mr-4">
        <div class="cursor-pointer" @click="$emit('editUser')">
          <edit-icon />
        </div>
        <div class="cursor-pointer" @click="$emit('deleteUser')">
          <trash-icon />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import AvatarBubble from "../../../components/global/AvatarBubble.vue";
import EditIcon from "../../../components/icons/EditIcon.vue";
import TrashIcon from "../../../components/icons/TrashIcon.vue";
import { CUSTOMER_USERS_TYPES, STORE_TYPES } from "../../../store/types";

export default defineComponent({
  components: { EditIcon, TrashIcon, AvatarBubble },
  name: "Receiver",
  emits: ["editUser", "deleteUser"],
  props: {
    editContainer: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
    },
  },

  setup() {
    const store = useStore();
    const GET_CUSTOMER_USER = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_USERS}/${CUSTOMER_USERS_TYPES.GET_CUSTOMER_USER}`
      ];
    });

    return {
      GET_CUSTOMER_USER,
    };
  },
});
</script>
