<template>
  <bottom-up-modal
    :title="$t('edit_groups_2')"
    :isVisible="isVisible"
    @closeModal="$emit('close')"
  >
    <div class=" mt-7 mb-7 flex flex-row w-full gap-3">
      <input-text
        class="w-1/2"
        :id="'group_name'"
        :label="$t('group_name')"
        :name="'group_name'"
        :labelClass="'bg-white'"
        v-model="GET_DATA_MODEL.name"
      />

      <input-select
        :id="'individual_fields'"
        class="w-1/2"
        :activeColor="'grey-fontgrey'"
        :name="'individual_fields'"
        :label="$t('individual_fields')"
        :defaultColor="'grey-light'"
        :labelClass="'bg-white'"
        :background="'bg-white'"
        :isSearch="true"
        :nameColumn="'key'"
        ref="dropdown"
        :options="GET_DATA_FIELDS"
        :activeItems="
          GET_DATA_MODEL.dataFields ? GET_DATA_MODEL.dataFields : []
        "
        @changeItem="
          (item) => {
            select_field(item);
            $refs.dropdown.close();
          }
        "
      />
    </div>
    <div v-if="GET_DATA_MODEL != null && GET_DATA_MODEL.dataFields != null">
      <draggable
        v-model="GET_DATA_MODEL.dataFields"
        handle=".handle"
        item-key="id"
      >
        <template #item="{element}">
          <div class=" flex flex-row items-center mb-2">
            <div class="p-2 handle cursor-pointer "><drag-icon /></div>
            <div class="w-full bg-primary-lightest rounded-md p-2 ">
              <div class="font-semibold">
                {{ element.key }}
              </div>
            </div>
          </div>
        </template>
      </draggable>
    </div>
    <div
      class="flex flex-col w-full"
      v-if="GET_DATA_MODEL != null && GET_DATA_MODEL.id != null"
    >
      <div class="mt-5 flex gap-4">
        <custom-button
          class="h-11 w-full sm:w-1/4 ml-auto"
          :bgColor="'bg-grey-fontgrey'"
          :textColor="'text-white'"
          :text="$t('abort')"
          :id="'abort'"
          @click="set_back()"
        />
        <custom-button
          class="h-11 w-full sm:w-1/4"
          :bgColor="'bg-primary'"
          :textColor="'text-white'"
          :text="$t('edit_2')"
          :id="'create_dataModel'"
          @click="UPDATE_DATA_MODEL()"
        />
      </div>
    </div>
    <div class="flex " v-else>
      <custom-button
        class="h-11 sm:w-1/5 w-1/2 ml-auto"
        :bgColor="'bg-primary'"
        :textColor="'text-white'"
        :text="$t('add')"
        @click="CREATE_DATA_MODEL()"
      />
    </div>
    <!-- --------------------------  Tarif LIST  -------------------------- -->
    <div class="w-full h-px bg-primary-light mt-7"></div>
    <div class="mt-7 font-semibold text-lg">
      {{ $t("groups") }}
    </div>

    <div class="flex items-center">
      <searchbar
        :id="'search'"
        v-model="state.search"
        :label="$t('search')"
        :labelClass="'bg-white'"
        :activeColor="'primary'"
        class="mt-3 w-1/2 bg-white"
        :placeHolder="$t('name')"
        @update="
          (value) => {
            state.search = value;
          }
        "
      />
    </div>

    <div
      class=" bg-white-dark mt-3 rounded-md w-full  hover:border-grey-fontgrey"
      :class="
        GET_DATA_MODEL.id == dataModel.id
          ? 'border-t-4 border-primary'
          : 'border border-transparent'
      "
      v-for="dataModel of filteredDataModels"
      :key="dataModel.id"
      :dataModel="dataModel"
    >
      <div class="p-2 flex items-center">
        <div class="flex flex-col">
          <div
            class="text-md font-semibold  box-content inline-block md:text-md"
          >
            {{ dataModel.name }}
          </div>
          <div
            class="text-md md:text-sm flex flex-row flex-wrap justify-start gap-1"
          >
            <div
              v-for="field of dataModel.dataFields"
              :key="field.id"
              :field="field"
            >
              <span> {{ field.key }}, </span>
            </div>
          </div>
        </div>
        <div class="ml-auto md:mr-7">
          <div class="flex items-center space-x-6 ">
            <div @click="edit_group(dataModel)">
              <edit-icon class="cursor-pointer mr-1" />
            </div>
            <trash-icon
              class="cursor-pointer"
              @click="delete_group(dataModel)"
            />
          </div>
        </div>
      </div>
    </div>
    <delete-modal
      :isVisible="state.openDeleteModal"
      @closeDeleteModal="state.openDeleteModal = false"
      @delete="DELETE_DATA_MODEL()"
    />
  </bottom-up-modal>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import draggable from "vuedraggable";

import EditIcon from "../../../components/icons/EditIcon.vue";

import CustomButton from "../../../components/input/CustomButton.vue";
import InputSelect from "../../../components/input/InputSelect.vue";

import InputText from "../../../components/input/InputText.vue";
import BottomUpModal from "../../../components/layout/BottomUpModal.vue";
import Searchbar from "../../../components/layout/Searchbar.vue";
import {
  AUTH_TYPES,
  DATA_FIELDS_TYPES,
  DATA_MODELS_TYPES,
  HELPERS_TYPES,
  STORE_TYPES,
} from "../../../store/types";
import DeleteModal from "./DeleteModal.vue";
import DragIcon from "../../../components/icons/DragIcon.vue";

export default defineComponent({
  components: {
    CustomButton,
    InputText,
    draggable,
    BottomUpModal,
    EditIcon,

    DeleteModal,
    InputSelect,
    Searchbar,
    DragIcon,
  },
  name: "EditGroupModal",
  emits: ["close"],
  props: {
    isVisible: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    /**
     *  State
     */
    const state = reactive({
      isOpen: false,
      openDeleteModal: false,
      search: "",
      dataFields: ["1", "2"],
    });

    /*
     * Computeds
     */

    // GET_GROUPS, GROUPS
    const GET_DATA_MODELS = computed(() => {
      return store.getters[
        `${STORE_TYPES.DATA_MODELS}/${DATA_MODELS_TYPES.GET_DATA_MODELS}`
      ];
    });
    const GET_DATA_MODEL = computed(() => {
      return store.getters[
        `${STORE_TYPES.DATA_MODELS}/${DATA_MODELS_TYPES.GET_DATA_MODEL}`
      ];
    });
    const GET_DELETE_DATA_MODEL = computed(() => {
      return store.getters[
        `${STORE_TYPES.DATA_MODELS}/${DATA_MODELS_TYPES.GET_DELETE_DATA_MODEL}`
      ];
    });
    const GET_DATA_FIELDS = computed(() => {
      return store.getters[
        `${STORE_TYPES.DATA_FIELDS}/${DATA_FIELDS_TYPES.GET_DATA_FIELDS}`
      ];
    });

    const GET_USER = computed(
      () => store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`]
    );

    const filteredDataModels = computed(() => {
      return store.getters[
        `${STORE_TYPES.DATA_MODELS}/${DATA_MODELS_TYPES.GET_DATA_MODELS}`
      ].filter((search) => {
        return search.name.toLowerCase().match(state.search.toLowerCase());
      });
    });

    /** Functions */

    function FETCH_DATA_MODELS() {
      store.dispatch(
        `${STORE_TYPES.DATA_MODELS}/${DATA_MODELS_TYPES.FETCH_DATA_MODELS}`,
        GET_USER.value.customer_id
      );
    }
    function FETCH_DATA_FIELDS() {
      store.dispatch(
        `${STORE_TYPES.DATA_FIELDS}/${DATA_FIELDS_TYPES.FETCH_DATA_FIELDS}`,
        GET_USER.value.customer_id
      );
    }

    // CREATE DataModel
    const CREATE_DATA_MODEL = async () => {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "create_dataModel"
      );

      const response = await store.dispatch(
        `${STORE_TYPES.DATA_MODELS}/${DATA_MODELS_TYPES.CREATE_DATA_MODEL}`,
        GET_USER.value.customer_id
      );
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "create_dataModel"
      );
      if (response.status === 200) {
        SET_DATA_MODEL({});
      }
    };

    // update DataModel
    const UPDATE_DATA_MODEL = async (input_id) => {
      if (GET_DATA_MODEL.value == null || GET_DATA_MODEL.value.id == null) {
        return;
      }
      let modelData = {
        customer_id: GET_USER.value.customer_id,
        id: GET_DATA_MODEL.value.id,
      };

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        input_id
      );

      await store.dispatch(
        `${STORE_TYPES.DATA_MODELS}/${DATA_MODELS_TYPES.UPDATE_DATA_MODEL}`,
        modelData
      );

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        input_id
      );
      SET_DATA_MODEL({});
    };

    // DELETE DATA FIELD
    const DELETE_DATA_MODEL = async () => {
      let data = {
        customer_id: GET_USER.value.customer_id,
        data_model: GET_DELETE_DATA_MODEL.value,
      };

      if (
        GET_DELETE_DATA_MODEL.value == null ||
        GET_DELETE_DATA_MODEL.value.id == null
      ) {
        return;
      }

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "delete"
      );

      await store.dispatch(
        `${STORE_TYPES.DATA_MODELS}/${DATA_MODELS_TYPES.DELETE_DATA_MODEL}`,
        data
      );

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "delete"
      );
      state.openDeleteModal = false;
    };

    // SETTTERS
    const SET_DATA_MODEL = (dataModel) => {
      return store.commit(
        `${STORE_TYPES.DATA_MODELS}/${DATA_MODELS_TYPES.SET_DATA_MODEL}`,
        dataModel
      );
    };
    const SET_DELETE_DATA_MODEL = (dataModel) => {
      return store.commit(
        `${STORE_TYPES.DATA_MODELS}/${DATA_MODELS_TYPES.SET_DELETE_DATA_MODEL}`,
        dataModel
      );
    };

    const delete_group = (dataModel) => {
      state.openDeleteModal = true;
      SET_DELETE_DATA_MODEL(dataModel);
    };

    const edit_group = (dataModel) => {
      SET_DATA_MODEL(dataModel);
      state.isOpen = true;
    };

    const set_back = () => {
      SET_DATA_MODEL({});
      state.isOpen = false;
    };

    const select_field = (item) => {
      // check if fields != null
      if (GET_DATA_MODEL.value.dataFields == null) {
        GET_DATA_MODEL.value.dataFields = [];
      }

      // check if is an array
      if (Array.isArray(GET_DATA_MODEL.value.dataFields)) {
        const findIndex = GET_DATA_MODEL.value.dataFields.findIndex(
          (myField) => {
            return myField.id == item.id;
          }
        );

        // if fieldIndex != -1 => splicen else push
        if (findIndex != -1) {
          GET_DATA_MODEL.value.dataFields.splice(findIndex, 1);
        } else {
          GET_DATA_MODEL.value.dataFields.push(item);
        }
      }
    };

    /** Mounted  */
    onMounted(() => {});

    watch(
      () => props.isVisible,
      (newValue) => {
        if (newValue == true) {
          if (GET_USER.value != null && GET_USER.value.id != null) {
            FETCH_DATA_MODELS(GET_USER.value.customer_id);
            FETCH_DATA_FIELDS(GET_USER.value.customer_id);
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    return {
      state,
      store,
      router,
      delete_group,
      edit_group,
      set_back,
      select_field,
      GET_DATA_MODELS,
      GET_DATA_FIELDS,
      GET_DATA_MODEL,
      filteredDataModels,
      UPDATE_DATA_MODEL,
      CREATE_DATA_MODEL,
      DELETE_DATA_MODEL,
    };
  },
});
</script>
