<template>
  <main
    class="flex flex-col items-center"
    v-if="GET_AUTH_USER != null && GET_AUTH_USER.id != null"
  >
    <main-content-container>
      <title-row
        :title="$t('settings')"
        class="font-roboto text-xlg text-black mt-3 mb-3"
      >
      </title-row>
      <div class="flex pb-2 border-b border-grey-light mb-8 mt-3">
        <avatar-bubble
          class="mr-4"
          ref="avatarBubble"
          :name="GET_AUTH_USER.fullname"
          :showEdit="true"
          :showDelete="GET_AUTH_USER.avatar == null ? false : true"
          @upload="$refs.fileinput.click()"
          :src="
            GET_AUTH_USER.avatar == null
              ? null
              : GET_AUTH_USER.avatar.picture_path
          "
          @deleteAvatar="DELETE_AVATAR()"
        >
        </avatar-bubble>

        <input
          id="file-upload"
          ref="fileinput"
          type="file"
          hidden
          @change="UPLOAD_AVATAR()"
        />

        <div class="flex flex-col items-start w-60">
          <div class="text-grey-dark">
            {{ GET_AUTH_USER.fullname }}
          </div>
          <div
            class="text-black font-semibold cursor-pointer"
            @click="edit_profile(GET_AUTH_USER)"
          >
            {{ $t("edit_profile") }}
          </div>
        </div>
      </div>
      <div>
        <settings-box
          v-for="(settingsGroup, index) in filteredSettings"
          :key="index"
          :settingsGroup="settingsGroup"
          @openmodal="
            (modalName) => {
              state.selectedModalName = modalName;
            }
          "
        />
      </div>

      <edit-users-modal
        @close="state.selectedModalName = ''"
        :isVisible="
          state.selectedModalName == 'editUsersModal' &&
            GET_AUTH_USER != null &&
            GET_AUTH_USER.permissions != []
        "
      />

      <shortcuts-modal
        @close="state.selectedModalName = ''"
        :isVisible="state.selectedModalName == 'shortcutsModal'"
      />
      <edit-field-modal
        @close="state.selectedModalName = ''"
        :isVisible="state.selectedModalName == 'editFieldModal'"
      />
      <edit-group-modal
        @close="state.selectedModalName = ''"
        :isVisible="state.selectedModalName == 'editGroupModal'"
      />
      <designation-modal
        @close="state.selectedModalName = ''"
        :isVisible="state.selectedModalName == 'DesignationModal'"
      />
    </main-content-container>
    <edit-profile-modal
      :isVisible="state.isOpen"
      @close="state.isOpen = false"
    />
  </main>
</template>

<script>
import { computed, defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

import TitleRow from "../../components/layout/TitleRow.vue";
import {
  AUTH_TYPES,
  HELPERS_TYPES,
  STORE_TYPES,
  USERS_TYPES,
} from "../../store/types";
import SettingsBox from "./components/SettingsBox.vue";
import ShortcutsModal from "./modals/ShortcutsModal.vue";
import AvatarBubble from "../../components/global/AvatarBubble.vue";
import EditUsersModal from "./modals/EditUsersModal.vue";

import MainContentContainer from "../../components/global/MainContentContainer.vue";

import EditProfileModal from "./modals/EditProfileModal.vue";
import EditGroupModal from "./modals/EditGroupModal.vue";
import EditFieldModal from "./modals/EditFieldModal.vue";
import DesignationModal from "./modals/DesignationModal.vue";

export default defineComponent({
  components: {
    TitleRow,
    SettingsBox,
    ShortcutsModal,
    AvatarBubble,
    EditUsersModal,
    MainContentContainer,
    EditProfileModal,
    EditGroupModal,
    EditFieldModal,
    DesignationModal,
  },

  setup() {
    const i18n = useI18n();
    const store = useStore();

    const fileinput = ref();
    const avatarBubble = ref();
    /**
     *  State
     */
    const state = reactive({
      isOpen: false,

      filters: {
        search: "",
      },
      selectedModalName: "",

      settings: [
        {
          header: i18n.t("edit_users_2"),
          elements: [
            {
              name: i18n.t("edit_users"),
              description: i18n.t("edit_users_text"),
              modalId: "editUsersModal",
            },
          ],
        },

        {
          header: i18n.t("Individualisation"),
          elements: [
            {
              name: i18n.t("edit_fields"),
              description: i18n.t("edit_fields_text"),
              modalId: "editFieldModal",
            },
            {
              name: i18n.t("edit_groups"),
              description: i18n.t("edit_groups_text"),
              modalId: "editGroupModal",
            },
            {
              name: i18n.t("product_carrier_designation_2"),
              description: i18n.t("product_carrier_designation_text"),
              modalId: "DesignationModal",
            },
          ],
        },
        {
          header: i18n.t("shortcuts"),
          elements: [
            {
              name: i18n.t("speedUpWork"),
              description: i18n.t("useShortcutsText"),
              modalId: "shortcutsModal",
            },
          ],
        },
      ],
    });

    /**
     *  Functions
     */
    function SET_FILTER_ACTIVE_ROUTE() {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_FILTER_ACTIVE_ROUTE}`
      );
    }

    const SET_USER = (user) => {
      return store.commit(`${STORE_TYPES.USERS}/${USERS_TYPES.SET_USER}`, user);
    };

    const edit_profile = (GET_AUTH_USER) => {
      SET_USER(GET_AUTH_USER);
      state.isOpen = true;
    };

    /**
     *  Computed
     */
    const filteredSettings = computed(() => {
      const search = state.filters.search.toLowerCase();
      return state.settings.map((setting) => {
        return {
          header: setting.header,
          elements: setting.elements.filter(function(element) {
            if (
              element.name.toLowerCase().indexOf(search) > -1 ||
              element.description.toLowerCase().indexOf(search) > -1
            ) {
              return true;
            } else {
              return false;
            }
          }),
        };
      });
    });
    const GET_AUTH_USER = computed(() => {
      return store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`];
    });

    const UPLOAD_AVATAR = async () => {
      let data = new FormData();
      let avatar = fileinput.value.files[0];

      avatarBubble.value.upload_fake_image(avatar);

      data.append("avatar", avatar);
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "isLoadingData"
      );
      await store.dispatch(
        `${STORE_TYPES.AUTH}/${AUTH_TYPES.UPLOAD_AVATAR}`,
        data
      );

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "isLoadingData"
      );
    };

    const DELETE_AVATAR = async () => {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "isLoadingData"
      );

      await store.dispatch(
        `${STORE_TYPES.AUTH}/${AUTH_TYPES.DELETE_AVATAR}`,
        GET_AUTH_USER.value
      );

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "isLoadingData"
      );
    };

    return {
      state,
      SET_FILTER_ACTIVE_ROUTE,
      filteredSettings,
      GET_AUTH_USER,
      edit_profile,
      fileinput,
      UPLOAD_AVATAR,
      DELETE_AVATAR,
      avatarBubble,
    };
  },
});
</script>
<style lang="scss">
.justify-start-important {
  justify-content: flex-start !important;
}
</style>
