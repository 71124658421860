<template>
  <div
    class=" p-3 rounded-lg  transition-all border-l-4 "
    :class="isActive ? 'border-primary' : 'border-transparent'"
  >
    <div class="flex flex-row justify-between items-center">
      <div>
        <h3 class=" text-black font-semibold">
          {{ header }}
        </h3>
        <h3>{{ description }}</h3>
      </div>
      <slot name="icons"></slot>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "RegularCard",
  props: {
    header: {
      type: String,
      default: "",
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {};
  },
});
</script>
