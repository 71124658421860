<template>
  <bottom-up-modal
    :title="$t('edit_profile')"
    :isVisible="isVisible"
    @closeModal="$emit('close')"
    v-if="GET_AUTH_USER != null && GET_AUTH_USER.id != null"
  >
    <div class="flex flex-row cursor-pointer items-center">
      <avatar-bubble
        class="mr-4"
        ref="avatarBubble"
        :name="GET_AUTH_USER.fullname"
        :showEdit="true"
        :showDelete="GET_AUTH_USER.avatar == null ? false : true"
        @upload="$refs.fileinput.click()"
        :src="
          GET_AUTH_USER.avatar == null
            ? null
            : GET_AUTH_USER.avatar.picture_path
        "
        @deleteAvatar="DELETE_AVATAR()"
      >
      </avatar-bubble>
      <input
        id="file-upload"
        ref="fileinput"
        type="file"
        name="avatar"
        hidden
        @change="UPLOAD_AVATAR()"
      />

      <div class="text-grey-dark">
        {{ $t("upload_profile_picture") }}
      </div>
      <div class="ml-3"><upload-icon-2 /></div>
    </div>
    <div class="flex mt-4 flex-wrap">
      <input-text
        class="w-full mb-5 sm:flex-1 sm:mr-4"
        :id="'profile_first_name'"
        :label="$t('first_name')"
        :name="'first_name'"
        :labelClass="'bg-white'"
        v-model="GET_AUTH_USER.firstname"
      ></input-text>
      <input-text
        :id="'profile_last_name'"
        :name="'last_name'"
        :labelClass="'bg-white'"
        class="w-full mb-5 sm:flex-1 sm:mr-4"
        :label="$t('last_name')"
        v-model="GET_AUTH_USER.lastname"
      ></input-text>
      <input-text
        :id="'profile_email'"
        :name="'email'"
        :labelClass="'bg-white'"
        class="w-full mb-5 sm:flex-1"
        :label="$t('email')"
        v-model="GET_AUTH_USER.email"
      ></input-text>
    </div>
    <!-- ---------------------  Password Form--------------------- -->
    <custom-button
      @click="state.showPasswords = !state.showPasswords"
      class="mt-2 w-40"
      :text="!state.showPasswords ? $t('password_change') : $t('cancel')"
      :textColor="'text-black'"
      :bgColor="'bg-transparent'"
      :id="'password-change-button'"
    >
      <template #icon>
        <icon-container
          class="mr-2"
          :size="'7'"
          :icon="'lock-closed-icon'"
          :iconFill="'fill-black'"
        ></icon-container>
      </template>
    </custom-button>
    <height-transition>
      <div
        class="height-item transition-all flex mt-2 py-3 overflow-hidden flex-wrap"
        v-if="state.showPasswords"
        :class="state.showPasswords ? 'h-52 sm:h-14' : 'h-0'"
      >
        <input-text
          :labelClass="'bg-white'"
          class="w-full mb-4 sm:flex-1 sm:mr-4"
          :id="'profile_password_old'"
          type="password"
          :label="$t('password_old')"
          :name="'password_old'"
          v-model="GET_AUTH_USER.old_password"
        ></input-text>
        <input-text
          :labelClass="'bg-white'"
          class="w-full mb-4 sm:flex-1 sm:mr-4"
          :id="'profile_password'"
          type="password"
          :label="$t('password')"
          :name="'password'"
          v-model="GET_AUTH_USER.password"
        ></input-text>
        <input-text
          :labelClass="'bg-white'"
          class="w-full mb-4 sm:flex-1"
          :id="'profile_password_confirmation'"
          type="password"
          :label="$t('password_repeat')"
          :name="'password_confirmation'"
          v-model="GET_AUTH_USER.password_confirmation"
        ></input-text></div
    ></height-transition>
    <input-checkbox
      class="mt-5 "
      :id="'profile_two_factor'"
      v-model="GET_AUTH_USER.has_2fa"
      @change="GET_AUTH_USER.has_2fa = !GET_AUTH_USER.has_2fa"
      :defaultColor="'grey-fontgrey'"
      :activeColor="'primary'"
      :name="'has_two_factor_enabled'"
      :label="$t('two_fa')"
    ></input-checkbox>
    <div class="flex flex-row justify-center sm:justify-end sm:mt-5 mt-6">
      <custom-button
        id="profile-edit-button"
        @click="UPDATE_PROFILE()"
        class="h-11 w-44"
        :text="$t('save')"
        :textColor="'text-white'"
        :bgColor="'bg-primary'"
      ></custom-button>
    </div>
  </bottom-up-modal>
</template>

<script>
import { computed, defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import AvatarBubble from "../../../components/global/AvatarBubble.vue";
import IconContainer from "../../../components/global/IconContainer.vue";
import UploadIcon2 from "../../../components/icons/UploadIcon2.vue";
import CustomButton from "../../../components/input/CustomButton.vue";
import InputCheckbox from "../../../components/input/InputCheckbox.vue";
import InputText from "../../../components/input/InputText.vue";
import BottomUpModal from "../../../components/layout/BottomUpModal.vue";
import HeightTransition from "../../../components/transitions/HeightTransition.vue";
import { AUTH_TYPES, HELPERS_TYPES, STORE_TYPES } from "../../../store/types";

export default defineComponent({
  components: {
    AvatarBubble,
    CustomButton,
    IconContainer,
    InputText,
    HeightTransition,
    InputCheckbox,
    BottomUpModal,
    UploadIcon2,
  },
  name: "EditProfileModal",
  emits: ["close"],
  props: {
    isVisible: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, ctx) {
    const store = useStore();
    const fileinput = ref();
    const avatarBubble = ref();

    /**
     * State
     */
    const state = reactive({
      user: {
        has_2fa: false,
      },
      showPasswords: false,
    });

    /**
     *  Computed
     */

    const GET_AUTH_USER = computed(
      () => store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`]
    );

    /**
     * Functions
     */
    const UPDATE_PROFILE = async () => {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "profile-edit-button"
      );
      const response = await store.dispatch(
        `${STORE_TYPES.AUTH}/${AUTH_TYPES.UPDATE_PROFILE}`,
        GET_AUTH_USER.value
      );

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "profile-edit-button"
      );
      if (response.status === 200) {
        ctx.emit("close");
      }
    };

    const UPLOAD_AVATAR = async () => {
      let data = new FormData();
      let avatar = fileinput.value.files[0];
      avatarBubble.value.upload_fake_image(avatar);
      data.append("avatar", avatar);
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "isLoadingData"
      );
      await store.dispatch(
        `${STORE_TYPES.AUTH}/${AUTH_TYPES.UPLOAD_AVATAR}`,
        data
      );

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "isLoadingData"
      );
    };

    const DELETE_AVATAR = async () => {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "isLoadingData"
      );
      await store.dispatch(
        `${STORE_TYPES.AUTH}/${AUTH_TYPES.DELETE_AVATAR}`,
        GET_AUTH_USER.value
      );

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "isLoadingData"
      );
    };

    return {
      // State
      state,

      fileinput,
      //Computed
      GET_AUTH_USER,
      // Functions
      UPDATE_PROFILE,
      UPLOAD_AVATAR,
      DELETE_AVATAR,
      avatarBubble,
    };
  },
});
</script>
